.footer {
  height: 5.2rem;
  margin-top: 3.2rem;
}

.logos {
  right: auto;
  left: auto;
  width: 30rem;

  color: black;
  background-color: none;
}

.logos a {
  color: var(--website-font-color);
}

.logos a:hover {
  color: var(--navbar-hover-color);
}

.footer-linkedin-icon {
  margin-right: 4rem;
  background-color: white;
  height: fit-content;
  padding: 1.2rem;
  border-radius: 50%;
  box-shadow: 0 0.5rem 0.5rem var(--box-shadow-color);
  font-size: 1.4rem;
}

.footer-mail-icon {
  font-size: 1.68rem;
  padding: 1.6rem;
  margin-left: 4rem;

  border-radius: 50%;
  background-color: white;
  height: fit-content;
  box-shadow: 0 0.5rem 0.5rem var(--box-shadow-color);
}
/*
position: absolute;
  right: 6.1rem;
  top: 1.1rem;
right: 1.6rem;
top: 1rem;
*/
