.resume-box {
  margin-right: auto;
  margin-left: auto;
  margin-top: 1.6rem;
  width: fit-content;
  padding: 1rem;
}

.download-icon {
  font-size: 2rem;
  padding-top: 0.2rem;
  margin-right: 0.8rem;
}

.resume-text {
  font-size: 1.4rem;
  font-family: var(--website-font);
  color: white;
}

.resume-button {
  text-transform: none !important;
  color: white !important;
}
