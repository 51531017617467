/* Plus icon starts here */
.plus-icon {
  height: 4rem;
  width: auto;
  padding-bottom: 0.8rem;
  transition-property: transform;
  transition-duration: 1s;
  float: right;
  top: 1rem;
  right: 0.8rem;
  position: absolute;
}

.plus-icon button {
  color: var(--website-font-color);
  background: none;
  width: 50px;
  height: 50px;
  border: 0;
  font-size: 1.5em;
  position: relative;
}

.plus-icon button span {
  position: absolute;
  transition: 300ms;

  background: var(--website-font-color);
  border-radius: 2px;
}

.plus-icon button:hover span {
  background: var(--navbar-hover-color);
}

/* Create the "+" shape by positioning the spans absolutely */
.plus-icon button span:first-child {
  top: 25%;
  bottom: 25%;
  width: 10%;
  left: 45%;
}

.plus-icon button span:last-child {
  left: 25%;
  right: 25%;
  height: 10%;
  top: 45%;
}

/* Morph the shape when the .plus-icon button is hovered over */
.expanded-button button span {
  transform: rotate(90deg);
}

.expanded-button button span:last-child {
  left: 50%;
  right: 50%;
}

/* 
 *
 * Plus icon ends here. margin top used throughout.
 * 
 */

.event {
  width: 100%;
  box-shadow: 0 0 0.5rem var(--box-shadow-color);
  background-color: white;
  height: fit-content;
  min-height: 7.2rem;
  margin-top: 1.6rem;
  border-radius: 0.5rem;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.small-event {
  text-align: center;
  padding-top: 1.6rem;
}

.event-image {
  height: auto;
  max-width: 13rem;
  padding-left: 1rem;
  margin-top: auto;
  margin-bottom: auto;
  float: left;
}

.company-wrapper {
  height: 7.2rem;
  float: left;
  display: flex;
  vertical-align: middle;
}

.small-company-wrapper {
  height: 4rem;
  display: flex;
  vertical-align: middle;
  justify-content: center;
}

.small-event-image {
  height: 4rem;
  width: auto;
  max-width: 50vw;
  padding-left: 1rem;
}

.event-image-border {
  padding-right: 1rem;
  height: fit-content;
  border-left: 0.2rem solid var(--website-font-color);
  margin-left: 1rem;
}

.event-title {
  font: var(--website-font);
  color: var(--website-font-color);
  font-size: 2.4rem;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
  margin-top: 1rem;
}

.company-name {
  display: inline;
  padding-left: 1rem;
  font: var(--website-font);
  color: var(--website-font-color);
  font-size: 2.4rem;
  font-weight: 500;
}

.small-event-image-border {
  margin-right: 1.6rem;
  height: fit-content;
  border-top: 0.2rem solid gray;
  margin-left: 1.6rem;
  margin-bottom: 0;
  font-size: 1.6rem;
  padding-top: 0.8rem;
}

.expanded-info {
  width: 100%;
  background: #fcfcfc;
  padding-top: 0rem;
  padding-right: 1.6rem;
  padding-bottom: 0rem;
  padding-left: 1.6rem;
  position: relative;
  bottom: 0;
  box-shadow: 0 0.5rem 0.5rem var(--box-shadow-color);
  border-top: 0.1rem solid lightgray;
  z-index: 0;
  font-size: 1.8rem;
  font-family: var(--website-font);
  color: var(--website-font-color);
  line-height: 2;
}

.collapsed {
  visibility: hidden;
  padding: 0;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  max-height: 0rem;
  transition: all 0.3s ease-out;
  overflow: hidden;
}

.expanded {
  max-height: 100rem;
  transition: all 0.5s ease-in;
  overflow: hidden;
}

.unround-bottom-borders {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.small-expanded-info {
  font-size: 1.6rem;
}

.info-padding {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
