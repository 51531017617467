.modal {
  font-size: 16px;
}

.navbar-mail-icon {
  font-size: 1.2rem;

  padding-top: 0.5rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.navbar-mail-icon:hover {
  color: var(--navbar-hover-color);
}

.input-field {
  width: 100%;
  height: fit-content;
  padding: 1rem 2rem !important;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
  border: 0.1rem solid #aaa;
  border-radius: 0.4rem;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.input-field:focus {
  outline: none;
}

.mail-popover {
  font-size: 1.6rem;
  position: absolute;
  right: 0rem;
  top: 5.2rem;
  background: white;
  padding: 1.6rem;
  /*box-shadow: 0 0.5rem 0.5rem var(--box-shadow-color);*/
  width: 25.6rem;
  height: 20rem;
  border: 0.1rem solid lightgray;
  transition: cubic-bezier();
}

.mail-popover a {
  height: 4.6rem;
  border: 0.1rem solid #aaa;
  background-color: #aaa;
  font-size: 1.6rem;
  color: whitesmoke;
  text-transform: capitalize;
  text-align: left !important;
  pointer-events: none;
}

.mail-popover span {
}

.mail-popover a:hover {
  background-color: #aaa;
  color: whitesmoke;
}

.enabled a {
  background-color: #1976d2;
  border: none;
  pointer-events: all;
}

.enabled a:hover {
  background-color: #1565c0 !important;
  color: whitesmoke !important;
}
