.project-card {
  height: auto;
  width: 100%;
  max-width: 100%;
  box-shadow: 0 0 0.5rem var(--box-shadow-color);
  background-color: white;
  border-radius: 0.5rem !important;
  position: relative;
  padding-bottom: 0.8rem;
  margin-top: 1.6rem;
}

.project-box {
  background: #fcfcfc;
  border-radius: 0.5rem;
  z-index: 1;
}

.project-title {
  font-family: var(--website-font);
  color: var(--website-font-color);
  font-size: 2.4rem;
  font-weight: normal;
  padding-bottom: 1.6rem;
  background-color: white;
  padding-top: 1.6rem;
  border-bottom: 0.1rem solid lightgray;
}

.project-text {
  padding: 3.2rem;
  padding-bottom: 0;
  font-family: var(--website-font);
  color: var(--website-font-color);
  font-size: 1.6rem;
}

.project-video video {
  margin: 2.4rem;
  width: calc(100% - 2.4rem - 2.4rem);
  background: white;
  height: 32.1rem;
  border: 0.05rem solid #ccc;

  /*
  background: black;
  height: 32rem;
  */
}
