.my-navbar {
  width: 100%;
  background: var(--navbar-background-color);
  color: var(--navbar-text-color);
  font-family: var(--website-font);
  padding-left: 2.4rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  box-shadow: 0 0 0.5rem var(--box-shadow-color);
  z-index: 10;
  top: 0rem;
  position: sticky;
  top: 0;
}

.brand {
  font-size: 2.4rem;
}

.brand-second {
  font-size: 2.4rem;
  font-weight: bold;
}

.navbar-link {
  font-size: 1.6rem;
  font-weight: normal;
  margin-right: 2.4rem;
  padding-top: 0.4rem;
  font-size: 2rem;
  vertical-align: bottom;
}

.my-navbar button {
  background: none;
  border: none;
}

.navbar-linkedin-icon {
  position: absolute;
  right: 6.1rem;
  top: 1.1rem;
}

.navbar-mail-icon-2 {
  font-size: 1.2rem;

  padding-top: 0.5rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.navbar-mail-icon-2:hover {
  color: var(--navbar-hover-color);
}

.navbar-links {
  margin-right: 7.8rem;
  padding-top: 0.3rem;
}

.navbar-anchor {
  padding-left: 0.8rem;
  padding-right: 0;
}

.my-navbar a {
  text-decoration: none;
  color: var(--navbar-text-color);
}

.my-navbar a:hover {
  color: var(--navbar-hover-color);
  cursor: pointer;
}

.my-navbar button {
  text-decoration: none;
  color: var(--navbar-text-color);
}

.my-navbar button:hover {
  color: var(--navbar-hover-color);
  cursor: pointer;
}
