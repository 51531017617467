.about-me {
  height: auto;
  width: 100%;
  margin-top: 1.6rem;
  background-color: white;
  box-shadow: 0 0 0.5rem var(--box-shadow-color);
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.my-pic {
  width: 50%;
  height: 100%;
  vertical-align: top;
  object-fit: cover;
  object-position: 50% 80%;
  margin-right: 0;
  float: left;
}

.about-me-text {
  height: 100%;

  display: flex;
  width: 50%;
  height: 52rem;
  padding: 3.2rem;
}

.left-large  {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.right-large {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.left-small {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.right-small {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.small-pic {
  object-fit: cover;
  object-position: 50% 10%;
  margin-right: 0;
  width: 100%;
  height: 30rem;
  background-color: white;
}

.small-text {
  width: 100%;
  height: fit-content;
  background-color: white;
  padding: 1.6rem;
}

.about-me-font {
  font: var(--website-font);
  color: var(--website-font-color);
  font-size: 1.6rem;
}

.about-me a {
  color: var(--website-font-color);
}

.about-me a:hover {
  color: var(--website-font-color);
}
