body {
  font-family: Helvetica, sans-serif;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: fit-content;
  padding: 1rem 2rem !important;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
  border: 0.1rem solid #aaa;
  border-radius: 0.4rem;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 4.6rem;
  width: 100%;
  border: 0.1rem solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 30rem;
  font-size: 1.6rem;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 1rem 2rem;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
