.page-background {
  background-color: var(--page-background);
  width: 100%;
  bottom: 0;
  height: fit-content;
  padding-top: 2.4rem;
}

.cards {
  justify-content: center;
  display: flex;
  flex-flow: row wrap;
  padding-right: 25vw;
  padding-left: 25vw;
  padding-bottom: 1.6rem;
  /* transition: all 0.5s linear; */
}

.page-background h2 {
  font: var(--website-font);
  color: var(--website-font-color);
  font-size: 2.4rem;
  margin-left: 1.6rem;
  width: calc(100vw - 3.2rem);
  display: inline-block;
  border-bottom: 0.1rem solid gray;
}

.min-page-width {
  min-width: 80rem;
}

.small {
  padding-right: 3.2rem;
  padding-left: 3.2rem;
  /*transition: all 0.5s linear;*/
}

.markdown {
  background-color: white !important;
}

.webchat--css-crxos-j7qwjs webchat--css-crxos-gznufz cci-chat-app {
  background-color: white !important;
}
