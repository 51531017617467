.education-card {
  height: auto;
  width: 100%;
  max-width: 100%;
  box-shadow: 0 0 0.5rem var(--box-shadow-color);
  background-color: white;
  border-radius: 0.5rem !important;
  position: relative;
  padding-bottom: 0.8rem;
  margin-top: 1.6rem;
}

.education-background {
  height: 10;
  width: 100%;
  z-index: 0;
  object-fit: cover;
  object-position: 50% -400%;
}

.education-background-wrapper {
  height: 10rem;
  overflow: hidden;
  background-color: lightgray;
}

.education-box {
  background: white;
  border-radius: 0.5rem;
  z-index: 1;
}

.education-text-large {
  padding-top: 1.6rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  font-family: var(--website-font);
  color: var(--website-font-color);
  font-size: 1.6rem;
  margin-left: 13.6rem;
}

.education-text-small {
  padding-top: 1.6rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  font-family: var(--website-font);
  color: var(--website-font-color);
  font-size: 1.6rem;
}

.education-text-title {
  font-weight: normal !important;
}

.education-pic-large {
  position: absolute;
  width: auto;
  height: 12rem;
  top: 1.6rem;

  left: 1.6rem;
  box-shadow: 0 0 0 0.1rem var(--box-shadow-color);
  z-index: 2;
}

.education-pic-small {
  object-fit: cover;
  object-position: 50% 40%;
  margin-right: 0;
  width: 100%;
  padding: 1.6rem;
  padding-bottom: 0;

  height: 10rem;
  object-position: 50% 50%;
  background-color: white;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.education-text-container {
  padding-bottom: 0.8rem;
}

.education-main-title {
  font-family: var(--website-font);
  color: var(--website-font-color);
  font-size: 2.4rem;
  font-weight: normal;
  padding-bottom: 1.6rem;
}

.education-profile-large {
  min-width: 100%;
  max-height: 12rem;
  box-shadow: 0 0 0 0.1rem var(--box-shadow-color);
  object-fit: cover;
  object-position: center;

  z-index: 2;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.education-text-large {
  padding-top: 0.8rem;
}

.education-main-title-large {
  font-size: 2.4rem;
  padding-bottom: 0.8rem;
}
