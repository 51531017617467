.timeline {
  font-family: var(--website-font);
  color: var(--website-font-color);
  font-size: 2.4rem;
  margin-left: 5rem;
}

.line-segment {
  border-left: solid;
  border-left-width: 0.2rem;
  border-left-color: var(--website-font-color);
  height: fit-content;
  padding-bottom: 1.6rem;
  padding-left: 0.8rem;
  margin-left: 5rem;
}
