html {
  font-size: 10px;
  scroll-padding-top: 6.8rem;
}

:root {
  --navbar-background-color: white;
  --navbar-text-color: #333333;
  --navbar-hover-color: gray;
  --website-font: Verdana;
  --website-font-color: #333333;
  --page-background: #f1f7f9;
  --box-shadow-color: lightgray;
  --button-color: #1976d2;
  --button-color-hover: #1565c0;
}

/* --navbar-background-color: #153243;
  --navbar-text-color: white;
  --navbar-hover-color: #cef9f2;
  --website-font: Verdana;
  --website-font-color: #333333;
  --page-background: #e8e8e8; */

.acknowledge-whitespace {
  white-space: pre-wrap;
}

.center-content {
  display: flex;
  justify-content: center;
}

.text-align-center {
  text-align: center;
}

.pink-background {
  background: pink;
}

.disable-focus {
  pointer-events: none;
}

.float-right {
  float: right;
}

.hidden {
  visibility: hidden;
  display: none;
}

h3 {
  font-size: 2.4rem !important;
  font-family: var(--website-font);
  color: var(--website-font-color);
  font-weight: normal;
  margin-bottom: 0 !important;
}
